import React, { useEffect, useState, CSSProperties, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from "../components/Header";
import { API_URL, Loader } from "../App";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import IsLogin from "../components/IsLogin";
import useRazorpay from "react-razorpay";
import MapComponent from '../components/MapComponent';

export function Checkout() {
    const navigate = useNavigate();
    const [Razorpay, isLoaded] = useRazorpay();
    const [amount, setAmount] = useState(0);
    const [couponDiscount, setcouponDiscount] = useState(0);
    const [address, setAddress] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    const handleAddressChange = (newAddress) => {
        setAddress(newAddress);
    };

    const handleLatChange = (newLat) => {
        setLat(newLat);
    };

    const handleLngChange = (newLng) => {
        setLng(newLng);
    }

    function applyCoupon(coupon_code) {
        const showAlert = (icon, text) => {
            Swal.fire({
                text: text,
                icon: icon,
                timer: 2000, // 2 seconds
                timerProgressBar: true,
                showConfirmButton: false,
                showCancelButton: false
            }).then((result) => {
                // This code will run when the timer expires
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });
    
        };
    
        if (coupon_code !== "") {
            fetch(API_URL + `/user-functions.php?method=apply_coupon&uid=&code=${coupon_code}`, {
                method: "GET",
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status === true) {
                        // Handle success
                        showAlert('success', 'Coupon code has been applied successfully.');
                        // demo response  {"status":true,"data":{"id":"1","code":"LW10","status":"1","type":"flat","amount":"50"}}
    
                        const couponType = data.data.type;
                        const couponAmount = parseFloat(data.data.amount);
    
                        if (couponType === "flat") {
                            setcouponDiscount(couponAmount);
                            setAmount(amount - couponAmount);
                        } else if (couponType === "percentage") {
                            const discountAmount = amount * (couponAmount / 100);
                            setcouponDiscount(discountAmount);
                            setAmount(amount - discountAmount);
                        } else {
                            // Handle other types if necessary
                            console.error('Unsupported coupon type');
                        }
    
                    } else {
                        // Handle failure
                        showAlert('warning', 'The coupon that you have applied is either invalid or already used.');
                    }
                })
                .catch(error => {
                    // Handle any errors from the fetch operation
                    showAlert('warning', 'The coupon that you have applied is either invalid or already used.');
                });
        } else {
            // Handle invalid coupon code
            showAlert('warning', 'Please enter a valid coupon code.');
        }
    }
    

    useEffect(() => {
        const data = new FormData();
        data.append("cart", localStorage.getItem("cart"));
        fetch(API_URL + "/cart-total.php", {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then(data => {
                setAmount(data.total);
            })
    }, [localStorage.getItem("cart")])

    function handlePaymentResponse(response) {
        // Prepare the additional form data
        const additionalData = {
            uid: localStorage.getItem("userUID") ? localStorage.getItem('userUID') : localStorage.getItem('hospitalUID'),
            patientDetails: JSON.stringify(patientDetails),
            prescription: prescriptionURL,
            date: selectedDate,
            time: selectedTime,
            coupon_code: coupon_code,
            cart: localStorage.getItem("cart"),
            address: address,
            lat: lat,
            lng: lng
        };

        // Merge payment response with additional data
        const payload = {
            ...additionalData,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature
        };

        // Send the combined data to your PHP API for verification
        fetch(API_URL + "/verify-signature.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(data => {
                if (data.status) {
                    // Payment verification successful
                    showAlert("success", "Payment Successful, redirecting to bookings.");
                } else {
                    // Payment verification failed
                    showAlert("warning", "Payment Failed, retry again later or choose another payment option.");
                }
            })
            .catch(error => {
                showAlert("error", "Internal Server Error: Unauthentic Signature.");
            });
    }


    const handlePayment = useCallback(async () => {
        setIsLoading(true);
        fetch(API_URL + `/create-order.php?amount=${amount}&uid=${localStorage.getItem('userUID')}`, {
            method: 'GET',
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                setIsLoading(false);
                const options: RazorpayOptions = {
                    key: "rzp_test_K9Jar6tPiA1BBA",
                    amount: data.amount,
                    currency: "INR",
                    name: "Jagruthi Lab",
                    description: "Payment for booking lab tests.",
                    image: "https://example.com/your_logo",
                    order_id: data.order_id,
                    handler: (res) => {
                        handlePaymentResponse(res);
                    },
                    prefill: {

                    },
                    notes: {
                        address: "Jagruthi Labortary",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                const rzpay = new Razorpay(options);
                rzpay.open();
            })


    }, [Razorpay]);

    const [isLoading, setIsLoading] = useState(true);
    const [coupon_code, setCouponCode] = useState("");
    const [patientDetails, setPatientDetails] = useState({
        name: "",
        age: "",
        phone: "",
        gender: "male",
        referringDoctor: "",
    });
    const [prescriptionFile, setPrescriptionFile] = useState(null); // New state for file
    const [prescriptionURL, setPrescriptionURL] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    setTimeout(() => {
        setIsLoading(false);
    }, 2000)

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const handleFileChange = (event) => {
        setPrescriptionFile(event.target.files[0]); // Update file state on file selection
    };

    function continue_to_payment() {
        if (patientDetails.name.trim() === "" || patientDetails.age === 0 || patientDetails.gender.trim() === "" || patientDetails.referringDoctor.trim() === "") {
            alert("Please fill in patient details.");
        }
        if (selectedDate === '' || selectedTime === '') {
            alert("Select slots data.")
        }
    }

    async function uploadPrescription() {
        if (prescriptionFile) {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('prescription', prescriptionFile);

            try {
                const response = await fetch(API_URL + '/handle-uploads.php', {
                    method: 'POST',
                    body: formData
                });
                const data = await response.json();
                if (data.url) {
                    // Handle success, e.g., update state or show message
                    setPrescriptionURL(data.url);
                    setIsLoading(false);
                    showAlert('success', 'Prescription uploaded successfully.')
                } else {
                    console.error('Error uploading prescription:', data.error);
                    // Handle error, e.g., show error message
                    setIsLoading(false);
                    showAlert('error', 'We are unable to upload prescription, try again after some time. If the issue is still there, get in touch with our support.')
                }
            } catch (error) {
                showAlert('error', 'We are unable to upload prescription, try again after some time. If the issue is still there, get in touch with our support.')
                // Handle network error or other unexpected errors
                setIsLoading(false);
            }
        } else {
            showAlert('warning', 'Select a prescription file before uploading, supported formats are - pdf, png, jpeg, jpg.')
            // Handle case where no file is selected
            setIsLoading(false);
        }
    }

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div>
            <IsLogin />
            <Loader loading={isLoading} />
            <Header />
            <div className="container" style={{ marginBottom: "100px" }}>


                <Tests />
                {/* Adding billing over here */}



                <div className="card p-3 mt-3">
                    <button className="btn btn-outline-primary" onClick={() => { setIsModalOpen(true) }}>Pick Location</button>
                </div>
                <div className={`modal fade ${isModalOpen ? 'show' : ''}`} tabIndex="-1"
                    style={{ display: isModalOpen ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Select Location</h5>
                                <button type="button" className="btn-close"
                                    onClick={() => setIsModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <MapComponent address={address} onAddressChange={handleAddressChange} onLatChange={handleLatChange} onLngChange={handleLngChange} />
                                <div className="mt-3">
                                    <input type="text" className="form-control" placeholder="Enter Complete Address"
                                        value={address} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn w-100 btn-primary"
                                    onClick={() => setIsModalOpen(false)}>Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Patient Information */}
                <div className="card p-3 mt-3">
                    <p className="fs-5 text-center">Patient Details</p>
                    <input
                        value={patientDetails.name}
                        onChange={(e) => setPatientDetails({ ...patientDetails, name: e.target.value })}
                        className="form-control mb-2"
                        placeholder="Name"
                    />
                    <input
                        value={patientDetails.phone}
                        onChange={(e) => setPatientDetails({ ...patientDetails, phone: e.target.value })}
                        className="form-control mb-2"
                        placeholder="Phone"
                    />
                    <input
                        value={patientDetails.age}
                        onChange={(e) => setPatientDetails({ ...patientDetails, age: e.target.value })}
                        className="form-control mb-2"
                        placeholder="Age"
                        type="number"
                    />
                    <select
                        className="bg-white border rounded p-2 mb-2"
                        value={patientDetails.gender}
                        onChange={(e) => setPatientDetails({ ...patientDetails, gender: e.target.value })}
                    >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to answer">Say not to answer</option>
                    </select>
                    <input value={patientDetails.referringDoctor} onChange={(e) => setPatientDetails({ ...patientDetails, referringDoctor: e.target.value })}
                        className="form-control mb-2" placeholder="Referring Doctor (Optional)" />
                    <div className="card p-3 text-center">
                        <p className="fs-5">Prescription (Optional)</p>
                        <input type="file" className="border p-2 rounded-2 mb-2" onChange={handleFileChange} />
                        <button onClick={uploadPrescription} className="btn btn-dark">Upload Prescription</button>
                    </div>
                </div>

                <div className="card p-3 mt-3">
                    <p className="fs-5 text-center">Select Slot</p>
                    <input
                        type="date"
                        placeholder="Select Date"
                        className="form-group w-100 bg-white border p-2 rounded-2 mb-2"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                    <input
                        type="time"
                        placeholder="Select Time"
                        className="form-group w-100 bg-white border p-2 rounded-2"
                        value={selectedTime}
                        onChange={handleTimeChange}
                    />
                </div>
                <div className='mb-3'></div>
                {localStorage.getItem("is_price_visible") == "Yes" ? (
                    <div className='card p-3'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
                            <p className='fs-5 bold m-0'>Disount</p>
                            <p className='fs-5 m-0'>₹{couponDiscount}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
                            <p className='fs-5 bold m-0'>Total Amount Payable</p>
                            <p className='fs-5 m-0'>₹{amount}</p>
                        </div>
                    </div>
                ) : (
                    <p></p>
                )
                }

                {/* Coupon Code */}
                {couponDiscount == 0 ? (
                    <div className="card p-3 mt-3 mb-5">
                        <input value={coupon_code} onChange={(e) => (setCouponCode(e.target.value))}
                            className="form-control mb-2" placeholder="Coupon Code" />
                        <button id="apply_coupon_button" onClick={() => {
                            applyCoupon(coupon_code)
                        }} className="btn btn-primary">Apply
                        </button>
                    </div>  
                ) : (
                    <div></div>
                )}
                

            </div>

            {/*  Showing confirm to payment  */}
            <div className={`d-flex p-2 bg-white align-items-center border`}
                style={{ justifyContent: "space-between", position: "fixed", right: 0, left: 0, bottom: 0 }}>
                <button className="btn btn-success w-100" onClick={() => {
                    confirmBooking(navigate, setIsLoading, localStorage.getItem("cart"), patientDetails, selectedDate, selectedTime, coupon_code, prescriptionURL, handlePayment, address, lat, lng)
                }}>Confirm Booking
                </button>
            </div>
        </div>

    );
}

function Tests() {
    const navigate = useNavigate();
    const [cart, setCart] = useState(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        return storedCart || [];
    });

    const [tests, setTests] = useState([]);

    function removeFromCart(id) {
        const updatedCart = cart.filter(itemId => itemId !== id);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        if (cart.length === 1) {
            navigate("/home");
        }
    }

    useEffect(() => {
        fetch(API_URL + `/user-functions.php?method=fetch_specific_tests&id=${JSON.stringify(cart)}`, {
            method: "GET",
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    setTests(data.tests);
                } else {
                    setTests([]);
                }
                console.log(tests);
            })
            .catch(error => console.error('Error fetching tests:', error));
    }, [cart]); // Include cart in the dependency array

    

    return (
        <div>
            {/*<div id="view-cart-button" className="card p-3 mb-3">*/}
            {/*    <button className="btn btn-primary w-100" onClick={() => {*/}
            {/*        document.getElementById("view-cart-button").hidden = true;*/}
            {/*        document.getElementById("all-cart-items").hidden = true;*/}
            {/*    }}>View Cart</button>*/}
            {/*</div>*/}
            <div id="all-cart-items" className="d-flex flex-wrap gap-3 card p-3">
                {tests.length > 0 ? (
                    tests.map(test => (
                        <div key={test.id} className="card p-4 rounded-1 w-100" style={{ width: '300px' }}>
                            <h6 className="mb-1 fs-5">{test.name}</h6>
                            <p className="text-muted mb-2">{test.description}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <button onClick={() => removeFromCart(test.id)} className="btn btn-secondary fs-6 w-100">Remove</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-muted mb-2 text-center">No tests found</p>
                )}
            </div>
        </div>
    );
}



function confirmBooking(navigate, setisloading, cart, patientDetails, selectedDate, selectedTime, coupon_code, prescription_url, openRazorpay, address, lat, lng) {

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    // Rezopay Payment Gateway
    let paymentOptions = {};

    if (localStorage.getItem("is_price_visible") === "Yes") {
        paymentOptions = {
            '1': 'Online (UPI/Card/Internet Banking)',
            '2': 'Offline Payment'
        }
    } else {
        paymentOptions = {
            '2': 'Offline Payment'
        }
    }


    const showPaymentOptions = () => {
        Swal.fire({
            title: 'Select Payment Method',
            input: 'select',
            inputOptions: paymentOptions,
            inputPlaceholder: 'Select a payment option',
            showCancelButton: true,
            inputValidator: function (value) {
                return new Promise(function (resolve, reject) {
                    if (value !== '') {
                        resolve();
                    } else {
                        resolve('You need to select a Payment Method');
                    }
                });
            }
        }).then(function (result) {
            if (result.isConfirmed) {
                if (result.value == 1) {
                    openRazorpay()
                } else if (result.value == 2) {
                    complete_checkout(navigate, setisloading, cart, patientDetails, selectedDate, selectedTime, coupon_code, prescription_url, address, lat, lng);
                }

            }
        });
    };

    if (!cart) {
        showAlert('error', 'Kindly add atleast one test in cart to preceed.');
        return null;
    }

    if (address == '') {
        showAlert('warning', 'Kindly pick your location before you could proceed.')
        return null;
    }

    if (!patientDetails.name || !patientDetails.age) {
        showAlert('warning', 'Kindly enter all the patient details first.');
    } else if (!selectedDate || !selectedTime) {
        showAlert('warning', 'Kindly select a valid slot date and time as per your choice.');
    } else {

        showPaymentOptions();


    }
}

function complete_checkout(navigate, setisloading, cart, patientDetails, selectedDate, selectedTime, coupon_code, prescription_url, address, lat, lng) {

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    setisloading(true);
    const data = new FormData();
    data.append("uid", localStorage.getItem("userUID"));
    data.append("patientDetails", JSON.stringify(patientDetails));
    data.append("prescription", prescription_url);
    data.append("date", selectedDate);
    data.append("time", selectedTime);
    data.append("coupon_code", coupon_code);
    data.append("cart", cart);
    data.append("address", address);
    data.append("lat", lat);
    data.append("lng", lng);

    fetch(API_URL + '/user-functions.php?method=confirm_booking', {
        method: 'POST',
        body: data
    })

        .then(function (res) {
            return res.json()
        })
        .then(function (data) {
            if (data.status === true) {
                localStorage.removeItem("cart");
                showAlert('success', 'Booking confirmed successfully. Redirecting to Bookings.');
                setTimeout(() => {
                    navigate("/bookings");
                }, 1500)
            } else {
                showAlert('error', 'We are unable to confirm your booking, kindly contact our support team.')
            }
        })

}
