import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {useNavigate} from "react-router-dom";

export default function AdminHeader() {
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem("key")){
            navigate("/admin/login");
        }
    }, [])
    return (
        <div>
            <nav className="navbar d-flex flex-row card navbar-light bg-light px-4 mb-3">
                <a className="navbar-brand" style={{fontSize: 25, fontWeight: 500}} href="#">Jagruthi Lab Admin Panel</a>
                {/*<div className="gap-4 d-none d-sm-block">*/}
                {/*    <a onClick={() => {navigate('/home')}} className="navbar-brand" href="#">Home</a>*/}
                {/*    <a onClick={() => {navigate('/bookings')}} className="navbar-brand" href="#">My Bookings</a>*/}
                {/*    <a onClick={() => {navigate('/checkout')}} className="navbar-brand" href="#">Cart</a>*/}
                {/*    <a onClick={() => {window.location.href = "tel:+919849183665"}} className="navbar-brand" href="#">Support</a>*/}
                {/*</div>*/}
            </nav>
            <div style={{position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 100}}>
                <div className="card p-2 px-5 bg-white w-100 d-flex flex-row gap-2 justify-content-center overflow-auto" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <button onClick={() => {
                            navigate('/admin/dashboard')
                        }} className="btn btn-outline-primary flex-shrink-0">Dashboard</button>
                        <button onClick={() => {
                            navigate('/admin/workers')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Workers</button>
                        <button onClick={() => {
                            navigate('/admin/hospitals')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Hospitals</button>
                        <button onClick={() => {
                            navigate('/admin/categories')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Categories</button>
                        <button onClick={() => {
                            navigate('/admin/tests')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Tests</button>
                        <button onClick={() => {
                            navigate('/admin/bookings')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Bookings</button>
                        <button onClick={() => {
                            navigate('/admin/employees')
                        }} className="btn btn-outline-primary flex-shrink-0">Manage Employees</button>
                        <button onClick={() => {
                            navigate('/admin/settings')
                        }} className="btn btn-outline-primary flex-shrink-0">General Settings</button>
                    </div>
                </div>
        </div>
    )
}
