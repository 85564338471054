import './App.css';
import Auth from './pages/Auth';
import { useState, useEffect, CSSProperties } from "react";
import Home from './pages/Home';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import React from "react";
import TestItems from "./components/TestItems";
import { Checkout } from "./pages/Checkout";
import { FadeLoader } from "react-spinners";
import HospitalAuth from "./pages/AuthExtendHospital";
import {Bookings} from "./pages/Bookings";
import {ViewBooking} from "./pages/ViewBooking";
import {Splash} from "./pages/SplashView";
import {EmployeeDashboard} from "./employee/Dashboard";
import WorkerAuth from "./employee/Login";
import {Wbooking} from "./employee/Booking"
import {AdminDashboard} from "./admin/dashboard";
import {ManageWorkers} from "./admin/manageWorkers";
import {Managehospitals} from "./admin/manageHospitals";
import {Managecategorys} from "./admin/manageCategories";
import {Managetests} from "./admin/manageTests";
import {ManageBookings} from "./admin/manageBookings";
import { LoginAdmin } from './admin/loginAdmin';


export const API_URL = "https://dreamdevinfotech.com/labwise/api";

function App() {

    return (
        <Router>
            <Routes>
                {/* Default Route */}
                <Route path="/" element={<Splash />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Auth />} />
                {/* Other Route */}
                <Route path="/category/:id" element={<TestItems />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/hospital" element={<HospitalAuth />} />
                <Route path="/bookings" element={<Bookings />} />
                <Route path="/viewbooking/:id" element={<ViewBooking />} />
                {/* Employee Paths */}
                <Route path="/worker/login" element={<WorkerAuth />} />
                <Route path="/worker" element={<EmployeeDashboard />} />
                <Route path="/worker/dashboard" element={<EmployeeDashboard />} />
                <Route path="/worker/booking/:id" element={<Wbooking />} />
                {/* Admin Paths   */}
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/workers" element={<ManageWorkers />} />
                <Route path="/admin/hospitals" element={<Managehospitals />} />
                <Route path="/admin/categories" element={<Managecategorys />} />
                <Route path="/admin/tests" element={<Managetests />} />
                <Route path="/admin/bookings" element={<ManageBookings />} />
                <Route path="/admin/login" element={<LoginAdmin />} />
            </Routes>
        </Router>
    );
}

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export function Loader({ loading }) {
    const [color, setColor] = useState("#ffffff");
    const [overlay, setOverlay] = useState('block');

    useEffect(() => {
        // Update overlay state based on loading prop
        if (!loading) {
            setOverlay('d-none');
        } else {
            setOverlay('block');
        }
    }, [loading]); // Run this effect whenever loading prop changes

    return (
        <div className={`overlay ${overlay}`}>
            <div className="loader-container">
                <FadeLoader
                    color={color}
                    loading={loading}
                    cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}

export default App;
