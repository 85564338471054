import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export function ManageWorkers() {
    const [isLoading, setLoading] = useState(false);
    const [workerid, setWorkerid] = useState("");
    const [workerName, setWorkerName] = useState("");
    const [workerEmail, setWorkerEmail] = useState("");
    const [workerPassword, setWorkerPassword] = useState("");
    const [workerStatus, setWorkerStatus] = useState("Online");
    const [workerList, setWorkerList] = useState([]);

    const [addForm, setaddForm] = useState(true);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listWorker", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setWorkerList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addWorker() {
        setLoading(true);
        const data = new FormData();
        data.append("email", workerEmail);
        data.append("password", workerPassword);
        data.append("full_name", workerName);
        data.append("status", workerStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=insertWorker", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Worker added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or worker already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or worker already exists.");
        });
    }

    function editWorker() {
        setLoading(true);
        const data = new FormData();
        data.append("id", workerid);
        data.append("email", workerEmail);
        data.append("password", workerPassword);
        data.append("full_name", workerName);
        data.append("status", workerStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=editWorker", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Worker edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deleteWorker(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deleteWorker", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Worker deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Worker</p>
                        <input value={workerName} onChange={(e) => {
                            setWorkerName(e.target.value);
                        }} className="form-control mb-2" placeholder="Full Name"/>
                        <input value={workerEmail} onChange={(e) => {
                            setWorkerEmail(e.target.value);
                        }} className="form-control mb-2" placeholder="Email"/>
                        <input value={workerPassword} onChange={(e) => {
                            setWorkerPassword(e.target.value);
                        }} className="form-control mb-2" placeholder="Password"/>
                        <select value={workerStatus} onChange={(e) => {
                            setWorkerStatus(e.target.value);
                        }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                            <option>Online</option>
                            <option>Offline</option>
                        </select>
                        <button onClick={addWorker} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit Worker</p>
                    <input value={workerName} onChange={(e) => {
                        setWorkerName(e.target.value);
                    }} className="form-control mb-2" placeholder="Full Name"/>
                    <input value={workerEmail} onChange={(e) => {
                        setWorkerEmail(e.target.value);
                    }} className="form-control mb-2" placeholder="Email"/>
                    <input value={workerPassword} onChange={(e) => {
                        setWorkerPassword(e.target.value);
                    }} className="form-control mb-2" placeholder="Password"/>
                    <select value={workerStatus} onChange={(e) => {
                        setWorkerStatus(e.target.value);
                    }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                        <option>Online</option>
                        <option>Offline</option>
                    </select>
                    <button onClick={editWorker} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        setWorkerid("");
                        setWorkerEmail("");
                        setWorkerName("")
                        setWorkerPassword("");
                        setaddForm(true)
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Name</th>
                                    <th>Login Time</th>
                                    <th>Status</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {workerList.map((worker, index) => (
                                    <tr key={index}>
                                        <td>{worker.email}</td>
                                        <td>{worker.full_name}</td>
                                        <td>{worker.loginTime}</td>
                                        <td>{worker.status}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setWorkerid(worker.uid);
                                                    setWorkerName(worker.full_name);
                                                    setWorkerPassword(worker.password);
                                                    setWorkerStatus(worker.status);
                                                    setWorkerEmail(worker.email);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteWorker(worker.uid)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
