import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../index.css';
import {API_URL, Loader} from "../App";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

export default function HospitalAuth() {
    // Setting states for the inputs and buttons here
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    function Login() {
        setIsLoading(true);
        fetch(API_URL + '/user-functions.php?method=hospitalAuth&email=' + email + '&password=' + password, {
            method: 'GET',
        })
            .then(function(res) {return res.json()})
            .then(function(data) {
                if (data.status === true) {
                    setIsLoading(false);
                    localStorage.setItem('userUID', data.uid);
                    localStorage.setItem('is_price_visible', data.is_price);
                    navigate("/home");
                } else {
                    showAlert('warning', 'Entered credentials are invalid, if the issue still there. Contact our support team.')
                    setIsLoading(false);

                }
            })
            .catch(err => setIsLoading(false))
    }


    return (
        <div className="container justify-content-center align-items-center">
            <Loader loading={isLoading} />
            {/* Just to give some space, I have added this div class*/}
            <div className="mb-4"></div>
            <div className="card p-4">
                <p className="fs-5 text-center">Hospital Login</p>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control mb-2" placeholder="Email" type="text"/>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mb-3" placeholder="Password" type="text"/>
                <button onClick={Login} className="btn btn-primary mb-3">Login</button>
            </div>
            <div id="recaptcha-container"></div>
        </div>
    )
}
