import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const MapComponent = ({ onAddressChange, onLatChange, onLngChange }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAQdpGLVu7w5DOUqkKucbfoqu59OPJ2oHc'
    });

    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setMarkerPosition({ lat: markerPosition.lat, lng: markerPosition.lng });
                setMapCenter({ lat: markerPosition.lat, lng: markerPosition.lng });

                onLatChange(markerPosition.lat);
                onLngChange(markerPosition.lng);

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ location: { lat: markerPosition.lat, lng: markerPosition.lng } }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        onAddressChange(results[0].formatted_address);
                    } else {
                        console.error('Geocoder failed due to: ' + status);
                    }
                });
            });
        } else {
            // Default center if geolocation is not available
            setMapCenter({ lat: -3.745, lng: -38.523 });
            setMarkerPosition({ lat: -3.745, lng: -38.523 });
        }
    }, [onAddressChange, onLatChange, onLngChange]);

    const handleLocationChange = useCallback((lat, lng) => {
        console.log('New location:', lat, lng);
        setMarkerPosition(prevPosition => ({
            ...prevPosition,
            lat: lat,
            lng: lng
        }));
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            console.log('Geocoding results:', results);
            console.log('Geocoding status:', status);
            if (status === 'OK' && results[0]) {
                onAddressChange(results[0].formatted_address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }, [onAddressChange]);

    const onMarkerDragEnd = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        console.log('Marker dragged to:', newLat, newLng);
        handleLocationChange(newLat, newLng);
    }, [handleLocationChange]);

    const onMapClick = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        console.log('Map clicked at:', newLat, newLng);
        handleLocationChange(newLat, newLng);
    }, [handleLocationChange]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={10}
            onClick={onMapClick}
        >
            <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
            />
        </GoogleMap>
    ) : <></>;
};

export default MapComponent;
